<template>
    <div id="index">
        <div class="container">
            <div class="top_text">
                <p>你的位置: 首页 / 购物车 / 确认下单</p>
            </div>
            <div class="content_individual">
                <div class="content_individual_top">
                    <div>收件信息</div>
                    <div style="overflow-x: hidden;overflow-x: scroll;height: 1.5rem;">
                        <div @click="addAddress" style="cursor: pointer;"><img src="../assets/images/address.png" style="width: 1.48rem;height: 1.48rem;"></div>
                        <div v-for="(item, index) in addressList" :key="index" class="addressBox" @click="addressClick(item)">
                            <img src="../assets/images/addressBorder.png" v-if="address_id == item.id" class="addressBox_image">
                            <div>
                                <div style="margin-bottom: 0.1rem;">{{ item.connect_name }}</div>
                                <div style="margin-bottom: 0.1rem;">{{ item.connect_mobile }}</div>
                                <div style="margin-bottom: 0.1rem;">{{ item.province_name }}{{ item.city_name }}{{ item.area_name }}</div>
                                <div style="line-height: 0.15rem">{{ item.detail_address }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content_details">
                    <div v-if="show == 1" style="font-size: 0.18rem;font-weight: 500;color: #333333;margin-left: 0.3rem;margin-top: 0.1rem;">装机单</div>
                    <div class="flex flex-child-center flex-justify-content-between" style="padding: 0.2rem 0; border-bottom: 0.01rem solid #E4E4E4;" v-for="(item, index) in list" :key="index">
                        <div class="flex flex-child-center flex-justify-content-center" style="width: 50%;padding: 0 0.3rem">
                            <div class="flex" style="width: 100%;">
                                <div class="gwcimg"><img :src="item.goods_image ? item.goods_image : (item.goods ? item.goods.goods_image : '')"></div>
                                <div class="gwctext">
                                    <div>{{ item.goods_name ? item.goods_name : (item.goods ? item.goods.goods_name : '') }}</div>
                                    <!--                                    <div style="font-size: 0.16rem;font-weight: 400;color: #999999;line-height: 16px;margin-top: 9px;">{{ item.goods_spec?item.goods_spec:item.goods.goods_spec }}</div>-->
                                    <div style="font-size: 0.16rem;font-weight: 400;color: #999999;line-height: 0.2rem!important;margin-top: 0.09rem;" v-if="item.goods_spec">
                                        {{ item.goods_spec }}</div>
                                    <div style="font-size: 0.16rem;font-weight: 400;color: #999999;line-height: 0.2rem;margin-top: 0.09rem;" v-if="item.goods">
                                        {{ item.goods ? item.goods.goods_spec : 0 }}</div>

                                </div>
                            </div>

                        </div>
                        <div class="flex flex-child-center flex-justify-content-start" style="width: 27%;font-weight: normal;font-size: 0.2rem;color: #333333;">
                            <span style="width: 1.3rem;display: block;margin-right: 0.03rem; text-align: right" v-if="pay_type == 'cash' && show == 0">
                                ￥{{ item.old_price ? item.old_price : (item.goods ? item.goods.old_price : 0) }}
                            </span>
                            <span style="width: 1.3rem;display: block;margin-right: 0.03rem; text-align: right" v-else>
                                ￥{{ item.price ? item.price : (item.goods ? item.goods.price : 0) }}
                            </span>
                            <span style="color: #999999;font-size: 0.16rem;width: 0.2rem;display: block; text-align: right">x{{ item.goods_num ? item.goods_num : item.number }}</span>
                        </div>
                        <div class="flex flex-child-center flex-justify-content-end" style="width: 21%;font-size: 0.2rem;font-weight: normal;color: #4B83F0;padding-right: 0.4rem;" v-if="pay_type == 'cash' && show == 0">
                            ￥{{ item.old_price ? (item.old_price * item.goods_num).toFixed(2) : (item.goods ? (item.goods.old_price * item.number).toFixed(2) : 0) }}
                        </div>
                        <div class="flex flex-child-center flex-justify-content-end" style="width: 21%;font-size: 0.2rem;font-weight: normal;color: #4B83F0;padding-right: 0.4rem;" v-else>
                            ￥{{ item.price ? (item.price * item.goods_num).toFixed(2) : (item.goods ? (item.goods.price * item.number).toFixed(2) : 0) }}
                        </div>
                    </div>
                </div>
                <div class="content_bottom flex flex-child-center flex-justify-content-between">
                    <el-input v-model="remark" placeholder="请输入备注内容"></el-input>
                </div>
                <div class="content_bottom flex flex-child-center flex-justify-content-between">
                    <div class="flex flex-child-center">
                        <div class="flex flex-child-center" style="width: 2.8rem;">
                            <el-radio v-model="pay_type" label="wechat" @input="radioInput">
                                <div class="flex flex-child-center">
                                    <div>
                                        <img src="../assets/images/wechat.png" />
                                    </div>
                                    <div>
                                        <div>线上支付</div>
                                        <div v-if="pay_type == 'wechat' && show == 0" style="font-size: 0.12rem;font-weight: 400;color: #CF7AE2;">优惠减¥{{ discounts }}</div>
                                    </div>
                                </div>
                            </el-radio>
                            <div v-if="pay_type == 'wechat' && show == 0" @click="details" style="width: 0.7rem;height: 0.2rem;background: #F3F3F3;border-radius: 0.12rem;font-size: 0.12rem;
                                  font-weight: 400;color: #999999;line-height: 0.2rem;cursor: pointer;">优惠明细</div>
                        </div>
                        <!-- <div class="flex flex-child-center" style="width: 2.8rem;">
                            <el-radio v-model="pay_type" label="alipay"  @input="radioInput">
                                <div class="flex flex-child-center">
                                    <div>
                                        <img src="../assets/images/alipay.png" />
                                    </div>
                                    <div>
                                        <div>支付宝支付</div>
                                        <div  v-if="pay_type == 'alipay'&& show == 0" style="font-size: 0.12rem;font-weight: 400;color: #CF7AE2;">优惠减¥{{ discounts }}</div>
                                    </div>
                                </div>
                            </el-radio>
                            <div  v-if="pay_type == 'alipay' && show == 0" @click="details"
                                  style="width: 0.7rem;height: 0.2rem;background: #F3F3F3;cursor: pointer;
                                  border-radius: 0.12rem;font-size: 0.12rem;font-weight: 400;color: #999999;line-height: 0.2rem;"
                            >优惠明细</div>
                        </div> -->
                        <div class="flex flex-child-center" style="width: 2.8rem;">
                            <el-radio v-model="pay_type" label="cash" @input="radioInput">
                                <div class="flex flex-child-center">
                                    <div>
                                        <img src="../assets/images/money.png" />
                                    </div>
                                    <div>
                                        <div>现款支付</div>
                                    </div>
                                </div>
                            </el-radio>
                        </div>

                    </div>
                    <div class="flex flex-child-center" style="height: 100%;">
                        <div style="font-size: 0.24rem;font-weight: 400;color: #CF7AE2;margin-right: 0.3rem;" class="flex flex-child-center">
                            总计 <span style="display:block;font-size: 0.32rem;font-weight: normal;">¥{{ totalPrice }}</span>
                        </div>
                        <div class="button" @click="payClick">确认并支付</div>
                    </div>
                </div>
                <div class="bottom_text">{{ $store.state.text }}</div>
            </div>

        </div>
        <!--弹出-->
        <el-dialog custom-class="dialog" title="新增收件人信息" :visible.sync="dialogFormVisible" :destroy-on-close="true" ref="dialog" @close="onDialogClose" :close-on-click-modal="false" width="28vw" top="20vh">
            <div class="dialog_con">
                <!--间值设置-->
                <div class="tan_body">
                    <el-form ref="form" label-width="1.2rem" style="max-height: unset;" :model="form" :rules="rules">
                        <el-form-item label="所在地区" prop="cityValue">
                            <el-cascader :options="cityOptions" v-model="form.cityValue" @change="cityChange">
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="收件人" prop="connect_name">
                            <el-input v-model="form.connect_name" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号码" prop="connect_mobile">
                            <el-input v-model="form.connect_mobile" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="详细地址" prop="detail_address">
                            <el-input type="textarea" v-model="form.detail_address" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div slot="footer" class="dialog-footer padding-30 no-padding-left no-padding-right no-padding-top">
                <el-button type="primary" round @click="buttonSubmit('form')">保存</el-button>
            </div>
        </el-dialog>
        <!--弹出-->
        <el-dialog custom-class="dialog" :visible.sync="dialogFormVisibleYH" :destroy-on-close="true" ref="dialog" @close="onDialogClose" :close-on-click-modal="false" width="50vw" top="15vh">
            <div class="dialog_con">
                <!--间值设置-->
                <div>
                    <div style="text-align: center;width: 100%;font-size: 0.2rem;font-weight: 500;color: #333333;margin-top: -0.45rem;">优惠明细</div>
                    <div class="flex  flex-wrap" style="max-height: 5rem;overflow: hidden;overflow: scroll;">
                        <div class="flex flex-child-center flex-justify-content-between" style="width: 100%; padding: 0.2rem 0; border-bottom: 0.01rem solid #E4E4E4;" v-for="(item, index) in list" :key="index">
                            <div class="flex flex-child-center flex-justify-content-center" style="width: 50%;padding: 0 0.3rem">
                                <div class="flex" style="width: 100%;">
                                    <div class="gwcimg"><img :src="item.goods_image ? item.goods_image : (item.goods ? item.goods.goods_image : '')"></div>
                                    <div class="gwctext">
                                        <div>{{ item.goods_name ? item.goods_name : (item.goods ? item.goods.goods_name : '') }}</div>
                                        <div style="font-size: 0.16rem;font-weight: 400;color: #999999;line-height: 0.2rem!important;margin-top: 0.09rem;" v-if="item.goods_spec">
                                            {{ item.goods_spec }}</div>
                                        <div style="font-size: 0.16rem;font-weight: 400;color: #999999;line-height: 0.2rem;margin-top: 0.09rem;" v-if="item.goods">
                                            {{ item.goods ? item.goods.goods_spec : '' }}</div>

                                    </div>
                                </div>

                            </div>
                            <div class="flex flex-child-center flex-justify-content-start" style="width: 27%;font-weight: normal;font-size: 0.2rem;color: #333333;">
                                <span style="width: 1.3rem;display: block;margin-right: 0.03rem; text-align: right" v-if="show == 0">￥{{ item.old_price ? item.old_price : (item.goods ? item.goods.old_price : 0) }}</span>
                                <span style="color: #999999;font-size: 0.16rem;width: 0.2rem;display: block; text-align: right">x{{ item.goods_num ? item.goods_num : item.number }}</span>
                            </div>
                            <div class="text-right" style="width: 21%;padding-right: 0.4rem;">
                                <div style="color: #999999;font-size: 0.2rem;font-weight: normal;text-decoration: line-through;" v-if="show == 0">
                                    ￥{{ item.old_price ? item.old_price * item.goods_num : (item.goods ? item.goods.old_price * item.number : 0) }}
                                </div>
                                <div style="color: #CF7AE2;font-size: 0.2rem;font-weight: normal;">
                                    <span style="font-weight: 400;">优惠价：</span>
                                    ￥{{ item.price ? item.price * item.goods_num : (item.goods ? item.goods.price * item.number : 0) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="font-size: 0.32rem;font-weight: normal;color: #CF7AE2;text-align: right;width: 100%;margin-top: 0.2rem;">
                        <span style="font-weight: 400;font-size: 0.24rem;">共优惠:</span> -¥{{ discounts }}
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "ConfirmOrder",
    data() {
        return {
            isAllCheck: false,
            list: [],
            chooseList: [],
            totalPrice: 0,
            addressList: [],
            dialogFormVisible: false,
            dialogFormVisibleYH: false,
            form: {
                cityValue: [], // 城市代码
                connect_name: '',
                connect_mobile: '',
                detail_address: '',
            },
            // 所在省市
            cityOptions: [],
            rules: {
                connect_name: [
                    { required: true, message: '请填写收件人姓名', trigger: 'blur' }
                ],
                connect_mobile: [
                    { required: true, message: '请填写联系电话', trigger: 'blur' }
                ],
                cityValue: [
                    { required: true, message: '请选择所在地区', trigger: 'blur' }
                ],
                detail_address: [
                    { required: true, message: '请填写详细地址', trigger: 'blur' }
                ],
            },
            address_id: '',
            remark: '',
            pay_type: 'wechat',
            show: 0,
            discounts: 0,
        };
    },
    computed: {
    },
    watch: {
    },
    async mounted() {
        this.$store.commit('settab', 2)
        // this.onList()
        this.onDetail()
        this.list = this.$store.state.shopList
        if (this.list[0].customize == 1) {
            this.show = 1
            console.log('this.show', this.show)
        }
        console.log('this.list', this.list)
        this.jisuan()
    },
    methods: {
        /**
         * 优惠明细
         * */
        details() {
            this.dialogFormVisibleYH = true
        },
        jisuan() {
            if (this.list[0].hasOwnProperty('goods')) {
                let b = 0
                let a = 0
                this.list.forEach(item => {
                    a = (item.number * item.goods.old_price) + a
                    b = (item.number * item.goods.price) + b
                })
                this.totalPrice = b
                this.totalPrice = this.totalPrice.toFixed(2)
                this.discounts = a - b
                this.discounts = this.discounts.toFixed(2)
            } else {
                let b = 0
                let a = 0
                this.list.forEach(item => {
                    b = (item.goods_num * item.price) + b
                    if (this.show == 0) {
                        a = (item.goods_num * item.old_price) + a
                    }
                })
                this.totalPrice = b
                this.totalPrice = this.totalPrice.toFixed(2)
                this.discounts = a - b
                this.discounts = this.discounts.toFixed(2)
            }
        },
        /**
         * 模板单选框
         * */
        radioInput(eve) {
            console.log(eve)
            if (eve == 'cash') {
                if (this.list[0].hasOwnProperty('goods')) {
                    let b = 0
                    this.list.forEach(item => {
                        b = (item.number * item.goods.old_price) + b
                    })
                    this.totalPrice = b
                    this.totalPrice = this.totalPrice.toFixed(2)
                } else {
                    if (this.show == 0) {
                        let b = 0
                        this.list.forEach(item => {
                            b = (item.goods_num * item.old_price) + b
                        })
                        this.totalPrice = b
                        this.totalPrice = this.totalPrice.toFixed(2)
                    }

                }
            } else {
                this.jisuan()
            }

        },
        /**
         * 支付并确认
         * */
        payClick() {
            if (this.address_id == '') {
                this.$message({ message: '请选择收货地址！' });
                return;
            }
            if (this.list[0].goods) {
                let cart_id = []
                this.list.map((item, index) => {
                    if (item.id) {
                        cart_id.push(item.id)
                    } else {
                        cart_id.push(item.goods_id)
                    }
                })
                //购物车结算
                this.$axios.post('api/kuerp/order/index/createCartOrder', {
                    address_id: this.address_id,
                    pay_type: this.pay_type,
                    remark: this.remark,
                    cart_id: cart_id
                }).then(res => {
                    //购物车数量接口
                    this.$store.dispatch('GetGwc')
                    if (res.data.code == 200) {
                        if (this.pay_type == 'cash') {
                            let a = {
                                order_num: res.data.data.order_num
                            }
                            this.$router.push({
                                name: 'orderDetails',
                                query: a
                            })
                        } else {
                            let a = {
                                pay_type: this.pay_type,
                                data: res.data.data,
                                totalPrice: this.totalPrice
                            }
                            this.$store.commit('setPay', a)
                            this.$router.push('/pay');
                        }

                    }
                })
            } else {
                let goods_list = []
                this.list.map((item, index) => {
                    // let b = {
                    //     goods_id:item.goods_id,
                    //     number:item.goods_num,
                    //     goods_unit:item.goods_unit
                    // }
                    goods_list.push(item)
                })
                //装机结算
                this.$axios.post('api/kuerp/order/index/createOrder', {
                    address_id: this.address_id,
                    pay_type: this.pay_type,
                    remark: this.remark,
                    order_type: this.show == 1 ? 2 : 1,
                    goods_list: goods_list
                }).then(res => {
                    if (res.data.code == 200) {
                        if (this.pay_type == 'cash') {
                            let a = {
                                order_num: res.data.data.order_num
                            }
                            this.$router.push({
                                name: 'orderDetails',
                                query: a
                            })
                        } else {
                            let a = {
                                pay_type: this.pay_type,
                                data: res.data.data,
                                totalPrice: this.totalPrice
                            }
                            this.$store.commit('setPay', a)
                            this.$router.push('/pay');
                        }

                    }
                })
            }

        },
        /**
         * 选择收货地址
         * */
        addressClick(item) {
            this.address_id = item.id
        },
        /**
         * 所在地区点击
         * */
        cityChange(value) {
            // console.log('value',value)
            // console.log('cityValue',this.cityValue)
        },
        /**
         * 地址接口
         * */
        initData() {
            this.$axios.post('api/kuerp/order/address/area', {}).then(res => {
                this.cityOptions = res.data.data
            })
        },
        /**
         * 清空弹框数据
         * */
        qingkong() {
            this.connect_name = ''
            this.connect_mobile = ''
            this.detail_address = ''
            this.cityValue = []
            this.is_default = 0
        },
        /**
         * 添加收货保存
         * */
        buttonSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$axios.post('api/kuerp/order/address/adddetail', {
                        connect_name: this.form.connect_name,
                        connect_mobile: this.form.connect_mobile,
                        province: this.form.cityValue[0],
                        city: this.form.cityValue[1],
                        area: this.form.cityValue[2],
                        detail_address: this.form.detail_address,
                        is_default: 0
                    }).then(res => {
                        console.log('res', res)
                        if (res.data.code == 200) {
                            this.onDetail();
                            this.dialogFormVisible = false
                            this.qingkong()
                        }
                    })

                } else {
                    return false;
                }
            });

        },
        /**
         * 添加地址按钮
         * */
        addAddress() {
            this.dialogFormVisible = true
            this.qingkong()
            this.initData()
        },
        /**
         * 新增弹框关闭
         * */
        onDialogClose() {
            this.dialogFormVisible = false
        },
        /**
         * 地址接口
         * */
        onDetail() {
            this.$axios.post('api/kuerp/order/address/getlist', {}).then(res => {
                this.addressList = res.data.data.list;
                this.addressList.forEach((item, index) => {
                    if (item.is_default == 1) {
                        this.address_id = item.id
                    }
                })
            })
        },

    },
}
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
    display: none;
}

::v-deep {
    .el-radio__input.is-checked+.el-radio__label {
        font-size: 0.16rem;
        font-weight: 400;
        color: #393E4C;
        line-height: 0.22rem;
    }

    .el-radio__input.is-checked .el-radio__inner::after {
        content: "";
        width: 0.15rem;
        height: 0.06rem;
        border: 0.02rem solid white;
        border-top: transparent;
        border-right: transparent;
        text-align: center;
        display: block;
        position: absolute;
        top: 0.05rem;
        left: 0.03rem;
        vertical-align: middle;
        transform: rotate(-45deg);
        border-radius: 0px;
        background: none;
    }

    .el-radio__inner {
        width: 0.24rem;
        height: 0.24rem;
    }

    .el-radio {
        display: flex;
        align-items: center;

        img {
            width: 0.28rem;
            height: 0.28rem;
            margin-right: 0.05rem;
        }
    }

    .el-dialog__footer {
        text-align: center;
    }

    .el-button.is-round {
        width: 3.44rem;
        height: 0.55rem;
        background: #4B83F0;
        border-radius: 0.04rem !important;
    }

    .el-textarea__inner {
        width: 3.44rem;
        font-size: 0.14rem;
        font-weight: 400;
        min-height: 0.94rem;
    }

    .el-input__inner {
        width: 3.44rem;
        font-size: 0.14rem;
        font-weight: 400;
    }

    .el-form-item__error {
        color: #CF7AE2;
    }

    .el-form-item.is-error .el-input__inner,
    .el-form-item.is-error .el-input__inner:focus,
    .el-form-item.is-error .el-textarea__inner,
    .el-form-item.is-error .el-textarea__inner:focus {
        border-color: #DCDFE6;
    }

    .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before,
    .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before {
        content: '';
    }
}

.gwcimg {
    width: 0.85rem;
    height: 0.85rem;
    margin-right: 0.2rem;

    img {
        width: 100%;
        height: 100%;
    }
}

.gwctext {
    width: 80%;
    font-size: 0.18rem;
    font-weight: 500;
    color: #333333;
    line-height: 0.3rem;
}

#index {
    //width: 100%;
    //height: 100vh;
    background: #434655;
}

.content_individual {
    width: 100%;
    //height: calc(100% - 100px);
    overflow: hidden;
    overflow-y: auto;
    background: #434655;
    padding-bottom: 0.5rem;

    .content_individual_top {
        height: 2.5rem;
        background: #F7F7F7;
        border-radius: 0.02rem;
        padding: 0.2rem;
        margin-bottom: 0.2rem;

        >div:nth-child(1) {
            font-size: 0.18rem;
            font-weight: 400;
            color: #333333;
            padding-bottom: 0.15rem;

        }

        >div:nth-child(2) {
            display: flex;

            >div {
                width: 1.48rem;
                height: 1.48rem;
                border-radius: 0.04rem;
                border: 0.01rem solid #CDCDCD;
                margin-right: 0.1rem;
                font-size: 0.12rem;
                font-weight: 400;
                color: #999999;
                line-height: 0.12rem;

                image {
                    width: 100%;
                    height: 100%;
                }
            }

            .addressBox_image {
                width: 1.48rem !important;
                height: 1.48rem !important;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }

            .addressBox {
                padding: 0.12rem 0.1rem;
                cursor: pointer;
                position: relative;

                div {
                    div {
                        margin-bottom: 0.05rem;
                    }
                }

            }
        }
    }

    .content_details {
        width: 100%;
        //height: 80%;
        background: #FFFFFF;
        //padding: 45px 0.2rem 30px 0.2rem;
        border-radius: 0.04rem;
        font-size: 0.18rem;
        font-weight: 400;
        overflow: hidden;
        overflow-y: auto;
    }

    .content_bottom {
        width: 100%;
        height: 0.8rem;
        background: #FFFFFF;
        border-radius: 0.04rem;
        margin-top: 0.2rem;
        padding-left: 0.74rem;
        font-size: 0.18rem;
        font-weight: 400;
        color: #666666;

        span {
            font-size: 0.24rem;
            font-weight: 400;
            color: #CF7AE2;
        }

        .button {
            width: 3.34rem;
            height: 100%;
            background: #4B83F0;
            border-radius: 0px 0.02rem 0.02rem 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.18rem;
            font-weight: 600;
            color: #F9F4E4;
            cursor: pointer;
        }

        ::v-deep {
            .el-input {
                width: 96%;
            }

            .el-input__inner {
                width: 100%;
                border: none;
                font-size: 0.18rem;
                font-weight: 400;
                color: #CCCCCC;
            }
        }
    }
}

.bottom_text {
    font-size: 0.14rem;
    font-weight: 500;
    color: #999999;
    text-align: center;
    margin-top: 0.5rem;
}

.container {
    width: 90%;
    margin: 0 auto;
    height: calc(100vh - 1.66rem);

}

.top_text {
    font-size: 0.14rem;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
    line-height: 0.2rem;
}
</style>
